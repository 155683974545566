/*
 *Displaying Order Items
 */
import { IColorCombination } from "../../../../types/IColorCombination";
import { IProductOptions } from "../../../../types/IProductOptions";
import { IOrderItem } from "../../../../types/Order/IOrderItem";
import OrderItemColorCombination from "./components/OrderItemColorCombination/OrderItemColorCombination";
import "./OrderItem.css";

interface IOrderItemsParams {
  orderItem: IOrderItem;
}

const OrderItem: React.FC<IOrderItemsParams> = ({ orderItem }) => {
  const additionalData = JSON.parse(orderItem.additional);
  //setJsonCartData(additionalData);
  const bandWidth = additionalData.attributes.band_width.option_label;
  const bandStyle = additionalData.attributes.band_style.option_label;

  //Getting details about order item
  const textValueForBandFrontMessage =
    orderItem.designPersonalizations.find(
      (item: IProductOptions) => item.attribute.code === "band_front_message",
    )?.textValue || undefined;

  const fontStyle =
    orderItem.designPersonalizations.find(
      (item: IProductOptions) => item.attribute.adminName === "Font Swatch",
    )?.attributeOption.adminName || undefined;

  const fontSize =
    orderItem.designPersonalizations.find(
      (item: IProductOptions) => item.attribute.adminName === "Band Text Size",
    )?.attributeOption.adminName || undefined;

  const textValueForBandBackMessage =
    orderItem.designPersonalizations.find(
      (item: IProductOptions) => item.attribute.code === "band_back_message",
    )?.textValue || undefined;

  const textValueForWrapMessage =
    orderItem.designPersonalizations.find(
      (item: IProductOptions) =>
        item.attribute.code === "band_wrap_around_message",
    )?.textValue || undefined;

  const textValueForInsideMessage =
    orderItem.designPersonalizations.find(
      (item: IProductOptions) => item.attribute.code === "band_inside_message",
    )?.textValue || undefined;

  const imageFrontStartClipart =
    orderItem.designPersonalizations.find(
      (item: IProductOptions) => item.attribute.code === "front_start",
    )?.textValue || undefined;

  const imageFrontEndClipart =
    orderItem.designPersonalizations.find(
      (item: IProductOptions) => item.attribute.code === "front_end",
    )?.textValue || undefined;

  const imageBackStartClipart =
    orderItem.designPersonalizations.find(
      (item: IProductOptions) => item.attribute.code === "back_start",
    )?.textValue || undefined;

  const imageBackEndClipart =
    orderItem.designPersonalizations.find(
      (item: IProductOptions) => item.attribute.code === "back_end",
    )?.textValue || undefined;

  const imageFrontWrapClipart =
    orderItem.designPersonalizations.find(
      (item: IProductOptions) => item.attribute.code === "wrap_start",
    )?.textValue || undefined;

  const imageBackWrapClipart =
    orderItem.designPersonalizations.find(
      (item: IProductOptions) => item.attribute.code === "wrap_end",
    )?.textValue || undefined;

  const colorCombinations = JSON.parse(
    orderItem.designPersonalizations.find(
      (item: IProductOptions) =>
        item.attribute.code === "band_color_combinations",
    )?.jsonValue || "[]",
  );

  const cliparts = [
    { label: "Front Start", image: imageFrontStartClipart },
    { label: "Front End", image: imageFrontEndClipart },
    { label: "Back Start", image: imageBackStartClipart },
    { label: "Back End", image: imageBackEndClipart },
    { label: "Wrap Front", image: imageFrontWrapClipart },
    { label: "Wrap Back", image: imageBackWrapClipart },
  ].filter((clipart) => clipart.image); //removes any entries with undefined images

  return (
    <div className="order-item">
      <div className="order-item-main-info">
        <div className="order-item-main-info-inner ">
          <div className="order-page-container cart-item-content">
            <div className="cart-item-info-content">
              <div className="cart-item-main-part-one">
                <p className="cart-item-variant-data">
                  <span>{bandWidth}</span>
                  <span>{bandStyle}</span>
                </p>
                <p className="cart-item-data">
                  <span>Font:</span>
                  <span>{fontStyle}</span>
                </p>
                <p className="cart-item-data">
                  <span>Font Size:</span>
                  <span>{fontSize}</span>
                </p>
                <p className="cart-item-data">
                  <span>Quantity:</span>
                  <span>{orderItem.qtyOrdered}</span>
                </p>
              </div>
              <div>
                {/*Messages*/}
                {textValueForBandFrontMessage && (
                  <div>
                    <strong>Front Message:</strong>{" "}
                    {textValueForBandFrontMessage}
                  </div>
                )}
                {textValueForBandBackMessage && (
                  <div>
                    <strong>Back Message:</strong> {textValueForBandBackMessage}
                  </div>
                )}
                {textValueForWrapMessage && (
                  <div>
                    <strong>Wrap Message:</strong> {textValueForWrapMessage}
                  </div>
                )}
                {textValueForInsideMessage && (
                  <div>
                    <strong>Inside Message:</strong> {textValueForInsideMessage}
                  </div>
                )}
                <p className="cart-item-variant-data">
                  Price: ${orderItem.price}/each
                </p>
              </div>
            </div>
          </div>
        </div>

        {/*Cliparts*/}
        <div className="cliparts-list">
          {cliparts.map((clipart, index) => (
            <div key={index} className="clipart-list-item">
              <p>{clipart.label}:</p>
              <img
                src={`storage/${clipart.image}`}
                alt={`${clipart.label} Clipart`}
              />
            </div>
          ))}
        </div>
      </div>

      {/*Color Combinations*/}
      <div className="order-page-container">
        <div className="cart-items-colorcombinations-list">
          {colorCombinations.map(
            (colorCombination: IColorCombination, index: number) => (
              <OrderItemColorCombination
                orderItemColorCombination={colorCombination}
                key={index}
              />
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderItem;
