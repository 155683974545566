/*showing clipart element*/
import { IonButton } from "@ionic/react";
import { IFile } from "../../../../../../../types/IFile";
import { IProductOptions } from "../../../../../../../types/IProductOptions";

interface IClipartButtonParams {
  image: IFile | undefined;
  clipartPosition: IProductOptions | undefined;
  setActiveClipart: (clipart: IProductOptions) => void;
  setShowClipartModal: (visible: boolean) => void;
}

const AddClipartButton: React.FC<IClipartButtonParams> = (props) => {
  return (
    <div className="clipart-option-content">
      <span className="clipart-title">{props.clipartPosition?.adminName}</span>

      <IonButton
        fill="clear"
        className="clipart-single-item"
        onClick={() => {
          if (props.clipartPosition) {
            props.setActiveClipart(props.clipartPosition);
            props.setShowClipartModal(true);
          }
        }}
      >
        {props.image ? (
          <img src={props.image.url} />
        ) : (
          <div className="plus-icon"></div>
        )}
      </IonButton>
    </div>
  );
};

export default AddClipartButton;
